import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../constants';
import Loader from '../Loader/Loader';
import OverlayLoader from '../Loader/OverlayLoader';
import { FROTA_BACKEND_URL } from '../../config';

interface UserNavLinksProps {
    onClick: () => void,
    userRole: string | null
}

const UserNavLinks: React.FC<UserNavLinksProps> = ({ onClick, userRole }) => {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const handleUserLogout = async () => {

        const response = await fetch(`${FROTA_BACKEND_URL}/users/logout`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('frotaToken')}`
            }
        })

        if (response.ok) {

        }

        localStorage.removeItem('frotaToken');
        localStorage.removeItem('refreshToken');
        sessionStorage.removeItem('user');
        setTimeout(() => {
            navigate('/')
        }, 100);
    }

    useEffect(() => {

    }, [])


    return (
        <>
            <OverlayLoader isLoading={isLoading} />
            <li className="nav-item" onClick={onClick}>
                <Link to={'/home'} className="nav-link">DASHBOARD</Link>
            </li>
            {/* <li className="nav-item" onClick={onClick}>
                <Link to={'/profile'} className="nav-link">PROFILE</Link>
            </li> */}
            <li className="nav-item" onClick={onClick}>
                <Link to={'/change-password'} className="nav-link">CHANGE PASSWORD</Link>
            </li>
            <li className="nav-item" onClick={onClick}>
                <a className="nav-link" onClick={handleUserLogout}>LOGOUT</a>
            </li>
            {/* <li className="nav-item" onClick={onClick}>
                <p className="nav-link" style={{
                    margin: 0,
                    borderRadius: '50px',
                    background: '#ffeeee'
                }}>CREDITS: {credits}</p>
            </li> */}
        </>
    );
};

export default UserNavLinks;
