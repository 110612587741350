// PrivateClassesReport.tsx
import React, { useState } from 'react';
import { PrivateClass } from '../../config/types';
import PrivateClassModal from '../Modals/PrivateClassModal';


interface PrivateClassReportProps {
    privateClasses: PrivateClass[];
    setPrivateClasses: (mechandise: PrivateClass[]) => void;
    isPrivateClassesUpdated: boolean;
    setIsPrivateClassesUpdated: (value: boolean) => void;
}

interface CurrentPrivateClass extends PrivateClass {
    id: number;
}


const PrivateClassReport: React.FC<PrivateClassReportProps> = ({ privateClasses, setPrivateClasses, isPrivateClassesUpdated, setIsPrivateClassesUpdated }) => {

    const [privateClassModalIsOpen, setPrivateClassModalIsOpen] = useState(false);
    const [isEditingPrivateClass, setIsEditingPrivateClass] = useState(false);
    const [currentPrivateClass, setCurrentPrivateClass] = useState<CurrentPrivateClass | null>(null);

    const openPrivateClassModal = () => setPrivateClassModalIsOpen(true);
    const closePrivateClassModal = () => {
        setPrivateClassModalIsOpen(false);
        setCurrentPrivateClass(null);
        setIsEditingPrivateClass(false);
    };

    const handleAddNewPrivateClass = () => {
        setCurrentPrivateClass({
            id: 0,
            studentName: '',
            price: 0,
            quantity: 1
        });
        setIsEditingPrivateClass(false);
        openPrivateClassModal();
    };

    const handleEditPrivateClass = (tempClass: CurrentPrivateClass) => {
        setCurrentPrivateClass(tempClass);
        setIsEditingPrivateClass(true);
        openPrivateClassModal();
    };

    const handleSavePrivateClass = (tempClass: CurrentPrivateClass) => {
        if (isEditingPrivateClass) {
            const tempPrivateClasses = [...privateClasses];
            tempPrivateClasses?.splice(tempClass.id, 1, tempClass)
            setPrivateClasses(tempPrivateClasses);
        } else {
            if (privateClasses?.length > 0) {
                setPrivateClasses([...privateClasses, tempClass]);
            } else {
                setPrivateClasses([tempClass]);
            }
        }
        setIsPrivateClassesUpdated(!isPrivateClassesUpdated);
        closePrivateClassModal();
    };

    const handleDeletePrivateClass = (privateClassIndex?: number) => {
        if (currentPrivateClass && (privateClassIndex || privateClassIndex === 0)) {
            const tempPrivateClasses = [...privateClasses]
            tempPrivateClasses?.splice(privateClassIndex, 1) // removed element from PrivateClasses
            setPrivateClasses(tempPrivateClasses);
        }
        setIsPrivateClassesUpdated(!isPrivateClassesUpdated);
        closePrivateClassModal();
    };

    return (
        <div className="section">
            <div className="section-header">
                <h3>PRIVATE CLASSES</h3>
                <button className="add-btn" onClick={handleAddNewPrivateClass}>ADD NEW</button>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th>STUDENT NAME</th>
                        <th>QUANTITY</th>
                        <th>TOTAL PRICE</th>
                    </tr>
                </thead>
                <tbody>
                    {privateClasses?.map((oneClass, index) => (
                        <tr key={index} onClick={() => handleEditPrivateClass({ ...oneClass, id: index })}>
                            <td>{oneClass.studentName}</td>
                            <td>{oneClass.quantity}</td>
                            <td>CHF {oneClass.price}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={2} className="total-label">TOTAL PRIVATE CLASS</td>
                        <td className="total-value">CHF {privateClasses?.length > 0 ? privateClasses.reduce((acc, cur) => acc + (cur.price), 0) : 0}</td>
                    </tr>
                </tfoot>
            </table>

            <PrivateClassModal
                isOpen={privateClassModalIsOpen}
                onRequestClose={closePrivateClassModal}
                onSave={handleSavePrivateClass}
                onDelete={isEditingPrivateClass ? handleDeletePrivateClass : undefined}
                privateClass={currentPrivateClass}
                isEditing={isEditingPrivateClass}
            />
        </div>
    );
};

export default PrivateClassReport;
