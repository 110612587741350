import React, { useState } from "react";
import { BASE_URL, passwordPattern } from "../../constants";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import OverlayLoader from "../../components/Loader/OverlayLoader";
import './ChangePassword.css'


const ChangePassword: React.FC = () => {
    const userToken = localStorage.getItem("frotaToken");

    const [isLoading, setIsLoading] = useState(false);

    const [currentPassword, setCurrentPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");

    const [viewPassword, setViewPassword] = useState<boolean>(false);
    const [viewNewPassword, setViewNewPassword] = useState<boolean>(false);
    const [viewConfirmNewPassword, setViewConfirmNewPassword] =
        useState<boolean>(false);

    const handlePasswordChange = async () => {
        setIsLoading(true);

        if (newPassword !== confirmNewPassword) {
            toast.error("Error: New Password and Confirm New Password do not match.");
            setIsLoading(false);
            return;
        }

        if (!currentPassword) {
            toast.error("Enter your Current Password.");
            setIsLoading(false);
            return;
        }
        if (!newPassword) {
            toast.error("Enter New Password.");
            setIsLoading(false);
            return;
        }
        if (!confirmNewPassword) {
            toast.error("Enter Confirm New Password.");
            setIsLoading(false);
            return;
        }

        const isValidPassword = passwordPattern.test(newPassword);
        if (!isValidPassword) {
            toast.error(
                "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long."
            );
            setIsLoading(false);
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}users/resetpassword`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userToken}`,
                },
                body: JSON.stringify({
                    oldPassword: currentPassword,
                    password: newPassword,
                }),
            });

            if (response.ok) {
                toast.success("Password Changed Successfully.");
                console.log("Password reset successful");
            } else {
                const json = await response.json();
                toast.error(json.message);
                console.error("Failed to reset password:", response.statusText);
            }
        } catch (error) {
            toast.error("Error Changing Password.");
            console.error("An error occurred while resetting password:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <OverlayLoader isLoading={isLoading} />
            <div className="title-container">
                <h2 className="text-center">CHANGE PASSWORD</h2>
            </div>
            <div className="password-fields">
                <div className="field">
                    <label htmlFor="current-password">Current Password</label>
                    <input
                        id="current-password"
                        type={viewPassword ? "text" : "password"}
                        placeholder="Current Password"
                        onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                    <div className="view-password-icon-container">
                        <FontAwesomeIcon
                            icon={viewPassword ? faEye : faEyeSlash}
                            onClick={() => setViewPassword(!viewPassword)}
                            className="view-password-icon"
                        />
                    </div>
                </div>
                <div className="field">
                    <label htmlFor="new-password">New Password</label>
                    <input
                        id="new-password"
                        type={viewNewPassword ? "text" : "password"}
                        placeholder="New Password"
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <div className="view-password-icon-container">
                        <FontAwesomeIcon
                            icon={viewNewPassword ? faEye : faEyeSlash}
                            onClick={() => setViewNewPassword(!viewNewPassword)}
                            className="view-password-icon"
                        />
                    </div>
                </div>
                <div className="field">
                    <label htmlFor="confirm-new-password">Confirm New Password</label>
                    <input
                        id="confirm-new-password"
                        type={viewConfirmNewPassword ? "text" : "password"}
                        placeholder="Confirm New Password"
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                    <div className="view-password-icon-container">
                        <FontAwesomeIcon
                            icon={viewConfirmNewPassword ? faEye : faEyeSlash}
                            onClick={() => setViewConfirmNewPassword(!viewConfirmNewPassword)}
                            className="view-password-icon"
                        />
                    </div>
                </div>
                <button
                    className="change-password-btn"
                    onClick={handlePasswordChange}
                >
                    Change Password
                </button>
            </div>
        </>
    );
};

export default ChangePassword;
