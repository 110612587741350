import React, { useState, FormEvent } from 'react';
import './login.css';
import { useNavigate } from 'react-router-dom';

import { BASE_URL } from '../../constants';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import OverlayLoader from '../../components/Loader/OverlayLoader';
import { FROTA_BACKEND_URL } from '../../config';

interface LoginProps {
    isAdmin: boolean;
}

const Login: React.FC<LoginProps> = ({ isAdmin }) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // const [rememberMe, setRememberMe] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);

    // const handleUserLogin = async () => {
    //     setIsLoading(true);
    //     try {
    //         const response = await fetch(`${FROTA_BACKEND_URL}/auth/sign_in`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({
    //                 email: email,
    //                 password: password,
    //             }),
    //         });

    //         if (response.ok) {
    //             const data = await response.json();
    //             const { token, refreshToken } = data;
    //             const { user } = data.data;

    //             // Store tokens and user data securely
    //             localStorage.setItem('frotaToken', token);
    //             localStorage.setItem('refreshToken', refreshToken);

    //             window.dispatchEvent(new Event("storage"));
    //             navigate('/home');

    //         } else {
    //             const error = await response.json();
    //             console.error('Login failed:', error);
    //             toast.error('Login failed. Please check your credentials.');
    //             setIsLoading(false);
    //         }
    //     } catch (error) {
    //         console.error('An error occurred:', error);
    //         toast.error('An unexpected error occurred. Please try again later.');
    //         setIsLoading(false);
    //     }
    // };

    const handleLogin = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${FROTA_BACKEND_URL}/${isAdmin ? 'auth/admin/sign_in' : 'auth/sign_in'}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                const { token, refreshToken } = data;

                // Store tokens and user data securely
                localStorage.setItem('frotaToken', token);
                localStorage.setItem('refreshToken', refreshToken);
                window.dispatchEvent(new Event("storage"));

                navigate('/home');
            } else {
                const error = await response.json();
                console.error('Login failed:', error);
                toast.error('Login failed. Please check your credentials.');
                setIsLoading(false);
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toast.error('An unexpected error occurred. Please try again later.');
            setIsLoading(false);
        }
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        const emailPattern = /^[a-zA-Z\d!#$%&'*+/=?^_`.{|}~-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;

        if (!email || !password) {
            toast.error('Email and Password fields are empty.');
            return;
        }

        if (!email) {
            toast.error('Enter a Valid Email.');
            return;
        }

        if (!password) {
            toast.error('Enter a Valid Password.');
            return;
        }

        if (!emailPattern.test(email)) {
            toast.error('Please enter a valid email address.');
            return;
        }
        setIsLoading(true);
        handleLogin();
    };

    return (
        <>
            <OverlayLoader isLoading={isLoading} />
            <div className="page-content">
                <h1 className="title text-center">DAILY REPORTS</h1>
                <div className="login-container" style={{ opacity: 1 }}>
                    <div className="form-container">
                        <div className="title-container">
                            <h3 className="title text-center">
                                {isAdmin ? 'ADMIN LOGIN' : 'USER LOGIN'}</h3>
                        </div>
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <div className="_um_row_1" style={{ marginBottom: '12px' }}>
                                <div className="field field-text field-email field-type_text" data-key="email">
                                    <div className="field-label">
                                        <label htmlFor="email">Email</label>
                                    </div>
                                    <div className="field-area">
                                        <input
                                            className="form-field valid"
                                            type="email"
                                            name="email"
                                            id="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder={isAdmin ? "Enter Admin's Email" : "Enter User's Email"}
                                            aria-invalid="false"
                                        />
                                    </div>
                                </div>
                                <div className="field field-password field-user_password field-password field-type_password" data-key="user_password">
                                    <div className="field-label">
                                        <label htmlFor="user_password">Password</label>
                                    </div>
                                    <div className="field-area">
                                        <input
                                            className="form-field valid"
                                            type={viewPassword ? 'text' : 'password'}
                                            name="user_password"
                                            id="user_password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder={isAdmin ? "Enter Admin's Password" : "Enter User's Password"}
                                            aria-invalid="false"
                                        />
                                        <div className='view-password-icon-container'>
                                            <FontAwesomeIcon
                                                icon={viewPassword ? faEye : faEyeSlash}
                                                onClick={() => setViewPassword(!viewPassword)}
                                                className='view-password-icon'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-alt">
                                <div className="login-btn-container">
                                    <button type="submit" className="login-button" id="submit-btn">
                                        {isAdmin ? 'Login as Admin' : 'Login'}
                                    </button>
                                </div>
                            </div>
                            {/* <div className="forgot-password-container">
                                <Link to="/forgot-password" className="forgot-text">
                                    Forgot your password?
                                </Link>
                            </div> */}
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
